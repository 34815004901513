<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.avance")}}</div>
              <div class="block-sarche mt-1">
                <div class="header__search d-flex mobile__wrap">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="space__input">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                     ></select-staff>
                  </div>
                  <div class="space__input">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="space__input">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" class="flex-style text-right mobil-p-a">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>

              <th v-if="columns.manager_id.show">
                {{ columns.manager_id.title }}
              </th>

              <th v-if="columns.periot.show">
                {{ columns.periot.title }}
              </th>

              <th v-if="columns.amount.show">
                {{ columns.amount.title }}
              </th>

              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.staff_id.show">
                <!-- <crm-input
                  :placeholder="columns.staff_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.staff_id"
                ></crm-input> -->
                <select-staff
                    size="mini"
                    :placeholder="columns.staff_id.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.staff_id"
                  >
                </select-staff>
              </th>

              <th v-if="columns.manager_id.show">
                    <select-staff
                    size="mini"
                    :placeholder="columns.staff_id.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.manager_id"
                  >
                </select-staff>
                <!-- <crm-input
                  :placeholder="columns.manager_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.manager_id"
                ></crm-input> -->
              </th>

              <th v-if="columns.periot.show">
                <!-- <crm-input
                  :placeholder="columns.periot.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.periot"
                ></crm-input> -->
                <el-select
                    :placeholder="columns.periot.title"
                    class="d-block"
                    v-model="filterForm.periot"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :size="'small'"
                    filterable
                    clearable
                    
                  >
                    <el-option
                    
                      :label="'сегодня'"
                      :value="'today'"
                    ></el-option>
                    <el-option
                    
                      :label="'завтра'"
                      :value="'tomorrow'"
                    ></el-option>
                    <el-option
                      :label="'На этой неделе'"
                      :value="'this_week'"
                    ></el-option>
                </el-select>
              </th>

              <th v-if="columns.amount.show">
                <crm-input
                  :placeholder="columns.amount.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :type="'number'"
                  v-model="filterForm.amount"
                ></crm-input>
              </th>

              <th v-if="columns.status.show">
                <el-select
                    :placeholder="columns.status.title"
                    class="d-block"
                    v-model="filterForm.status"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :size="'small'"
                    filterable
                    clearable
                    
                  >
                    <el-option
                    
                      :label="$t('message.waiting')"
                      :value="'waiting'"
                    ></el-option>
                    <el-option
                    
                      :label="$t('message.denied')"
                      :value="'deny'"
                    ></el-option>
                    <el-option
                    :label="$t('message.received')"
                      :value="'accept'"
                    ></el-option>
                </el-select>
              </th>

              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="salary in list" :key="salary.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ salary.id }}</td>

              <td v-if="columns.staff_id.show">
                {{
                  salary.staff
                    ? salary.staff.name + " " + salary.staff.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.manager_id.show">
                {{
                  salary.manager
                    ? salary.manager.name + " " + salary.manager.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.periot.show">
                {{ salary.periot == "today" ? "сегодня" : "" }}
                {{ salary.periot == "tomorrow" ? "завтра" : "" }}
                {{ salary.periot == "this_week" ? "На этой неделе" : "" }}
              </td>

              <td v-if="columns.amount.show">
                {{ Number(salary.amount).toLocaleString("fi-FI") }}
              </td>

              <td v-if="columns.status.show">
               <span style="color:#ffab43"> {{ salary.status == "waiting" ? $t('message.waiting'): "" }}</span>
               <span style="color:#35b3ef"> {{ salary.status == "accept" ?  $t('message.received') : "" }}</span>
               <span style="color:#c95454"> {{ salary.status == "deny" ?  $t('message.denied') : "" }}</span>
               <b :class="mode ? 'text__day2' : 'text__night2'" style="color:#00a523; font-weight:bold"> {{ salary.status == "payed" ?  $t('message.payed') : "" }}</b>
              </td>

              <td v-if="columns.comment.show">
                {{ salary.comment }}
              </td>
              <td v-if="columns.created_at.show">
                {{ salary.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ salary.updated_at }}
              </td>
              <td v-if="columns.settings.show">
                <el-button v-if="salary.status == 'accept'" @click="payAdvance(salary)" type="success" size="medium" class="remove-button">{{$t("Оплатить")}}</el-button>
              </td>
              <!-- <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="salary"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="paySalaryDialog"
      title="Avans Berish!"
      width="50%"
      :append-to-body="true"
      center
      ref="paySalaryDialog"
      :before-close="handleClose"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
      <div>
        <el-form ref="form" status-icon :model="form" :rules="arrivalRules" :class="mode ? 'text__day2' : 'text__night2'">
          <el-row :gutter="20">
            <el-col :span="12" class="mb-3" >
              <b :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.staff')}}:</b>
              {{
                selected&&selected.staff
                  ? selected.staff.name + " " + selected.staff.last_name
                  : ""
              }}
            </el-col>
            <el-col :span="12" class="mb-3">
              <b :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.avance')}}:</b>
              {{selected? Number(selected.amount).toLocaleString("fi-FI"):'' }}               
            </el-col>
            <el-col :span="24" class="mb-3">
              <b :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.comment')}}:</b>
              {{ selected?selected.comment:''}}
            </el-col>
            <el-col :span="24"><hr></el-col>
            <el-col :span="12">
              <el-form-item prop="date_of_end">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                  <span class="start_top">*</span>
                  {{ $t("message.salary") }} {{ $t("message.date") }}
                </span>
                <el-date-picker
                  class="w-100"
                  size="medium"
                  v-model="form.date_of_end"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="
                    $t('message.salary') + ' ' + $t('message.date')
                  "
                  :class="mode ? 'input__day' : 'input__night'"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="account_id">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                  <span class="start_top">*</span>
                  {{ $t("message.check") }}
                </span>
                <select-account
                  :id="form.account_id"
                  v-model="form.account_id"
                ></select-account>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="payment_type">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                  <span class="start_top">*</span>
                  {{ $t("message.payment_type") }}
                </span>
                <el-select
                  class="w-100"
                  v-model="form.payment_type"
                  size="medium"
                  :placeholder="$t('message.payment_type')"
                  :class="mode ? 'input__day' : 'input__night'"
                >
                  <el-option
                    v-for="(type, index) in payment_types"
                    :key="'payment_types' + index"
                    :label="type.label"
                    :value="type.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="payment_month">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">
                  {{ $t("message.payment_for") }}
                </span>
                <el-date-picker
                  class="w-100"
                  size="medium"
                  v-model="form.payment_month"
                  type="month"
                  :format="'MMMM yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.payment_for')"
                  :class="mode ? 'input__day' : 'input__night'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row  :gutter="20"
                v-if="form.payment_type == 'card'"
                v-loading="loadCard"
              >
              <el-col :span="8">
                <el-form-item>
                  <span
                    class="input--label d-block mb-0 line-h-24 title_data"
                    >{{ $t("message.bank") }}</span
                  >
                  <el-select
                    class="w-100"
                    v-model="form.card_id"
                    size="medium"
                    :placeholder="$t('message.bank')"
                    @change="changeCard"
                  >
                    <el-option
                      v-for="(card, index) in staffCards"
                      :key="'card-' + index"
                      :label="card.card_authority"
                      :value="card.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">{{
                  $t("message.card_number")
                }}</span>
               
                  <crm-input
                    size="medium"
                    :disabled="true"
                    :inputValue="form.card ? form.card.card_number : ''"
                    v-model="form.card_number"
                  ></crm-input>
                
              </el-col>
              <el-col :span="8">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24 title_data">
                  {{ $t("message.expiration_date") }}
                </span>
               
                  <crm-input
                    size="medium"
                    :disabled="true"
                    :inputValue="form.card ? form.card.expiry_date : ''"
                    v-model="form.card_expiry_date"
                  ></crm-input>
                
              </el-col>
          </el-row>
          
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitModel()"> Сохранить </el-button>
        <el-button type="warning" size="medium" @click="close()"> Закрыть </el-button>
      </span>
    </el-dialog>
  </div>
 
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import form from "@/utils/mixins/form";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list, dialog, form],

  data() {
    return {
      paySalaryDialog: false,
      loadCard: false,
      types: [
        {
          label: i18n.t("message.entrance"),
          value: "entrance",
        },
        {
          label: i18n.t("message.exit"),
          value: "exit",
        },
      ],
      payment_types: [
        { value: "card", label: "Пластик" },
        { value: "cash", label: "Наличный" },
      ],
      selected: {},
      loadingButton: false,
      arrivalRules: {
        payment_month: [
          {
            required: true,
            message: "Пожалуйста, введите Платеж за ",
            trigger: "change",
          },
        ],
        date_of_end: [
          {
            required: true,
            message: "Пожалуйста, введите Дата ",
            trigger: "change",
          },
        ],
        payment_type: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change",
          },
        ],
        account_id: [
          {
            required: true,
            message: "Пожалуйста, введите Счет ",
            trigger: "change",
          },
        ],
      },
    };
  },
  // mounted() {
  //   this.updateList();
  // },
  watch: {
    "form.payment_type": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
        }
      },
      deep: true,
    },
    "form.staff_id": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
        }
      },
      deep: true,
    },
    selected:{
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
        }
      },
      deep: true,
    },   
    "form.card": {
      handler: function () {},
      deep: true,
    },
  
  },
  computed: {
    ...mapGetters({
      list: "advanceSalary/list",
      staffCards: "staffTransaction/cards",
      columns: "advanceSalary/columns",
      pagination: "advanceSalary/pagination",
      statues: "advanceSalary/statues",
      filter: "advanceSalary/filter",
      sort: "advanceSalary/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "advanceSalary/advanceSalary",
      setPagination: "advanceSalary/setPagination",
      updateSort: "advanceSalary/updateSort",
      updateFilter: "advanceSalary/updateFilter",
      updateColumn: "advanceSalary/updateColumn",
      updatePagination: "advanceSalary/updatePagination",
      show: "advanceSalary/show",
      empty: "advanceSalary/empty",
      delete: "advanceSalary/destroy",
      save: "transaction/storeAdvanceSalary",
      refreshData: "advanceSalary/refreshData",
      getStaffCard: "staffTransaction/getCard",
    }),
    async getCard() {
      if (this.form.staff_id && !this.loadCard) {
        this.loadCard = true;
        await this.getStaffCard(this.form.staff_id)
          .then((res) => {
            this.loadCard = false;
          })
          .catch((err) => {
            this.loadCard = false;
            this.$alert(err);
          });
      }
    },
    changeCard(cardId) {
      if (this.staffCards) {
        this.form.card = JSON.parse(
          JSON.stringify(_.find(this.staffCards, ["id", cardId]))
        );
      }
    },

    payAdvance(advanceSalary) {
      this.selected = advanceSalary;
      this.form.advance_salary_id = advanceSalary.id;
      this.form.staff_id = advanceSalary.staff_id;
      this.paySalaryDialog = true;
    },

        submitModel() {
          this.$refs["form"].validate((valid) => {
            if (valid && !this.loadingButton ) {
              // this.form.paid_amount = this.selected.amount;
              this.loadingButton = true;
              this.save(this.form)
                .then((res) => {
                  // this.$alert(res);
                  if (res.status == 201) {
                    this.refreshData();
                    this.close();
                  }
                  this.loadingButton = false;
                })
                .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
                });
            }
          });
        },
        close(){
            this.form ={};
            this.selected = null;
            this.form.advance_salary_id = '';
            this.form.staff_id = '';
            this.paySalaryDialog = false;
        },
        handleClose(){
            this.form ={};
            this.selected = null;
            this.form.advance_salary_id = '';
            this.form.staff_id = '';
            this.paySalaryDialog = false;
        }


  },
};
</script>

<style lang="scss">
.card-table-header{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
      
  }
  input[type=number]{
    -moz-appearance: textfield;
  }
  
  .el-input__inner[type=number]{
    padding-right: 15px !important;
  }
}

</style>

